const LocationReviewsData = [
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Wendy White",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brian W.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Joshua Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "William M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Tracy Alexander",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tammy S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Johnny Brown",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lee H.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "Steven Willis",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joshua K.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Randy Flynn",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Justin K.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Steven Banks",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Erin T.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Tami Thomas",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tyler L.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Robert Oliver",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Elizabeth H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Janet Roach",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lisa C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Erin Barrett",
        "service_type": "Garage Door Off Track",
        "tech_name": "Todd B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Kimberly Murillo",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ashley B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Amy Wagner",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Maria S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Jessica Cowan",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amber T.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Tabitha Page",
        "service_type": "Garage Door Installation",
        "tech_name": "Renee B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Tina Martin",
        "service_type": "Garage Door Repair",
        "tech_name": "Heather C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Daniel Long",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kelli M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.8,
        "customer_name": "Whitney Maddox",
        "service_type": "Garage Door Repair",
        "tech_name": "Stephen S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Krystal Boyle",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ernest M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Thomas Palmer",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Amy B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.8,
        "customer_name": "Robert Alexander",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Laura P.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Charles Peters",
        "service_type": "Garage Door Off Track",
        "tech_name": "Natalie R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Carrie Simmons",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "George C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Melissa Jackson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jeffrey S.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Erin Holt",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jose B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-21",
        "review_rate": 5.0,
        "customer_name": "Timothy Soto",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Edward H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Jeremy Sawyer",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tara L.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Amanda Fowler",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Megan R.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Valerie Edwards",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "George G.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Melvin Barron",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Trevor T.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Laurie Payne",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Rodney T.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Paula Rodriguez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Johnny C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Justin Fleming",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kimberly G.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Patricia Davis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mary Y.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Connie Webb",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Chelsea R.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Logan Kim",
        "service_type": "Garage Door Services",
        "tech_name": "Cynthia P.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.8,
        "customer_name": "Johnny Whitney",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Eric S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Susan Collins",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kayla H.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Matthew Blackburn",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sean T.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Melissa Holmes",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Nancy S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Elijah Miller",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Elizabeth S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Rachel Obrien",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Summer R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Brent Boyd",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Cameron S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Kristina White",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Victoria M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Michael Wilson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christina C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.8,
        "customer_name": "Allison Scott",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christopher F.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-21",
        "review_rate": 5.0,
        "customer_name": "Russell Sanders",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael L.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Crystal Carr",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Lisa H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "Andrew Ellis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Deanna M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Martha Morales",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Teresa W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.8,
        "customer_name": "Lisa Turner",
        "service_type": "Garage Door Repair",
        "tech_name": "Christopher S.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Carrillo",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Shawn W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Melissa Robinson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Veronica S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Jill Hardy",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jason H.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.8,
        "customer_name": "Jessica Harmon",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joanna S.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Deborah Caldwell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Monique R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Terry West",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kelly C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Samuel Hanson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Steven G.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Stacy Larson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Robert M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Julie Rodgers MD",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "John F.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Stephanie Jennings",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Nicholas W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Laura Thornton",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Anne W.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Deborah Medina",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brent M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Amy Murphy",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kyle R.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Michael Martin",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jordan B.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Bush",
        "service_type": "Garage Door Repair",
        "tech_name": "James F.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "Jesse Andersen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Robert L.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Brian Suarez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "David J.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Alison Rivera",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Miguel M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Daniel Chung",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Justin L.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Ray Butler",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Deanna O.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Javier Tran",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rita L.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Tommy Hart",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Richard L.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Michael Jones",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Robert G.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Dana Mcintosh",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michelle M.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Veronica Wall",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mary E.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Vanessa Rogers",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Zachary J.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Maria Collins",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brittany W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-03",
        "review_rate": 5.0,
        "customer_name": "Rachel Perkins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Daniel R.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Micheal Collins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sheila H.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Steven Sanders",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Shelley E.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Katherine Griffin",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jeffrey S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Brown",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jon A.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Amanda Galloway",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brett B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Troy Rodriguez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Candace M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Erik Walton",
        "service_type": "Garage Door Insulation",
        "tech_name": "Heather L.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Lisa Kelley",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Savannah I.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Misty Perry",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Monica H.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Thomas Hartman",
        "service_type": "Garage Door Repair",
        "tech_name": "Spencer B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Ms. Kimberly Armstrong MD",
        "service_type": "Garage Door Services",
        "tech_name": "Kari M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Christopher King",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Andrew L.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Kimberly Smith",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Emily W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Tara Contreras",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "James S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Nicholas Peck",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Dominique G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Brad Schultz",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kelly W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Willie Rivers",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brett J.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Jennifer Lynch",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Samuel M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Michelle Ayers",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nicole H.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Lisa Conley",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Melissa S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Melanie Jones",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Samantha R.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Whitney Green",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brandon S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "David Roberts",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Madison M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.8,
        "customer_name": "Alexandra Griffin",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Rachel M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Michael Chan",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Terry A.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Tiffany Meyer",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Samuel H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Daniel Villanueva",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Richard C.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Sarah Allen",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Natalie C.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.8,
        "customer_name": "Steven Brown",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Katherine R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Michael Guerrero",
        "service_type": "Garage Door Insulation",
        "tech_name": "Anthony H.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Savannah Brown",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Mark G.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.8,
        "customer_name": "Michael Stewart",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Julia G.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.8,
        "customer_name": "Jonathan Avila",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Austin K.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Laura Shaffer",
        "service_type": "Garage Door Off Track",
        "tech_name": "Carolyn M.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Deborah Smith",
        "service_type": "Garage Door Services",
        "tech_name": "Michelle B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Jessica Mueller",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tammy T.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.8,
        "customer_name": "Jordan Kirby",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Andre T.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Stephanie Johnson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Richard G.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Jessica Myers",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Paul H.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Joshua Fernandez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Justin M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Christopher Pennington",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Isabella M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Kristy Sanchez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Katherine U.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Judith Soto",
        "service_type": "Garage Door Repair",
        "tech_name": "Daniel D.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "Angela Powell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Andrew H.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Justin Johnson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Eric L.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Troy Mitchell",
        "service_type": "Garage Door Opener",
        "tech_name": "Jason S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Cameron Gutierrez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jessica G.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Amanda Martinez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Erin C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Jessica Morales",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Rebecca L.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Melinda Moore",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Julian G.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Mrs. Monica Gutierrez MD",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Alyssa P.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Timothy Thomas",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kristen R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Jessica Frederick",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "John M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Steven Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Benjamin P.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.8,
        "customer_name": "Antonio Franco",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Andrew S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Eric Byrd",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kyle H.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Brian Bass",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Dominique S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Robin Blankenship",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Samuel B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Kara Nunez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Traci M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "James Gay",
        "service_type": "Garage Door Installation",
        "tech_name": "Valerie D.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Kimberly Becker",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Julia B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Tiffany Gould",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Charles A.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Jeremiah Chavez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lydia H.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "John Perez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Eric B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Jamie Williams",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Patricia D.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Patricia Miller",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jaclyn S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Gail Parker",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Carlos A.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "David Clark",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Juan Y.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-05",
        "review_rate": 5.0,
        "customer_name": "Jason Brooks",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Erica W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Jose Anderson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Johnny S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Brittany White",
        "service_type": "Garage Door Repair",
        "tech_name": "Tristan H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Steven Torres",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kimberly E.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Kari Mcclure",
        "service_type": "Garage Door Insulation",
        "tech_name": "John J.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Ryan English",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Alicia P.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Heather Murray",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sonya B.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Albert Hartman",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Stephanie M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Stacey Hughes",
        "service_type": "Garage Door Services",
        "tech_name": "Kayla L.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Monique Lee",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Robert F.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Sarah Gutierrez",
        "service_type": "Garage Door Repair",
        "tech_name": "Sheila A.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Denise Massey",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jesse S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Nancy Mcbride",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alan H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Anna Conway",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Mikayla G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.8,
        "customer_name": "Madeline Brown",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Angel S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Darrell Pierce",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michelle T.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Andrew Martinez",
        "service_type": "Garage Door Installation",
        "tech_name": "Erica W.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Abigail Moran",
        "service_type": "Garage Door Installation",
        "tech_name": "Abigail C.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Erik Moore",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jesse S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-28",
        "review_rate": 5.0,
        "customer_name": "Austin Sanchez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Patrick C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Madison Miller",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Mark B.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Ashley Black",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jay S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Jodi Jones",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Raymond F.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Donna Hudson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Dennis W.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Jessica Williams",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Adam F.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Brian Jones",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael L.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Laura Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Mark D.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Rebecca Little",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Allen Livingston",
        "service_type": "Garage Door Repair",
        "tech_name": "Marissa P.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Laura Phillips",
        "service_type": "Garage Door Installation",
        "tech_name": "John P.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Mathew Robinson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael E.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Terry Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "David B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Paul Contreras",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Renee T.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Andrew Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Samantha H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Jennifer Blanchard",
        "service_type": "Garage Door Insulation",
        "tech_name": "Nicholas C.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Scott Jones",
        "service_type": "Garage Door Off Track",
        "tech_name": "Derrick T.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Paul Burns",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Regina Y.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Ms. Abigail Sullivan",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ashley T.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Craig Cooper",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brenda W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "Jennifer Meyer",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Laurie W.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-02",
        "review_rate": 5.0,
        "customer_name": "Bryan Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Timothy O.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Evan Johnson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jesus A.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "James Berry",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jason D.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Diane Stewart",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brenda M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Abigail Jenkins",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael N.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Danielle Morgan",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Vanessa B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Matthew Edwards",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Melanie G.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.8,
        "customer_name": "Stephen Ward",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Rachel T.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Nicole Pratt",
        "service_type": "Garage Door Installation",
        "tech_name": "Ryan D.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.8,
        "customer_name": "Diana Harris",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John R.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "David George",
        "service_type": "Garage Door Installation",
        "tech_name": "Isaiah T.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Daniel Evans",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Richard G.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Jimmy Washington",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amy H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Nicole Hall",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Samuel J.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "John Kim",
        "service_type": "Garage Door Repair",
        "tech_name": "Catherine R.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Austin Ortiz",
        "service_type": "Garage Door Insulation",
        "tech_name": "Emily O.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Joe Curtis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tiffany J.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Kenneth Booker",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Joshua S.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Jessica Diaz",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Andrew W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Richard Klein",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Katherine C.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Julie Bautista",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jason K.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Christopher Moon",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joseph W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "John Zimmerman",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Shawn B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Ryan Mendoza",
        "service_type": "Garage Door Repair",
        "tech_name": "Tracy S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Laura Smith",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Heather J.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Kathleen Gonzales",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Elizabeth S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Susan Mendez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Timothy L.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-28",
        "review_rate": 5.0,
        "customer_name": "Karen Hall",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jose M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Yolanda Campbell",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jeffrey M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Jennifer Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Mary A.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Michael Ho",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Robert H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Timothy Moore",
        "service_type": "Garage Door Services",
        "tech_name": "Mitchell L.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Dustin Nguyen",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Elizabeth M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-29",
        "review_rate": 5.0,
        "customer_name": "Jamie Combs",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mary B.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Adrian Burton",
        "service_type": "Garage Door Repair",
        "tech_name": "Ashley B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Dylan Griffin",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Terrence W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Shannon Martinez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Edward V.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Tiffany Carpenter",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tina P.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Michelle Mason",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "John W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Robin Barrera",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Diana M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Anthony Gonzales",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Holly S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Joshua Watson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Todd H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.8,
        "customer_name": "John Hickman",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lisa W.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Andrew Wells",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "April R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Michael Martin",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "Kevin Barrera",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joe C.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Amber Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Drew J.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Kyle Williams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kathy G.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Melanie Bolton",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Henry H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Tyler Cain",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Beth H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Johnny Mcdonald",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Suzanne R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Daniel Miller",
        "service_type": "Garage Door Off Track",
        "tech_name": "Carmen H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Cheryl Lopez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amanda S.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "David Thomas",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Robert C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Jacqueline Hernandez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Anna W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Courtney Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michael H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Jill King",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "David O.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.8,
        "customer_name": "Daniel Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Andrew J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Kristin Marshall",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Steven T.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Tiffany Olson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "James B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Kelly Oconnell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Eric Lee",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Wendy M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Eric Nguyen",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christopher B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.8,
        "customer_name": "Nicole Fletcher",
        "service_type": "Garage Door Off Track",
        "tech_name": "Raymond L.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-19",
        "review_rate": 5.0,
        "customer_name": "Michael Rivera",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Megan J.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Brooke Johnson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lori D.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Erin Rollins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Emily C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Ashley Myers",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "James T.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.8,
        "customer_name": "Tamara Morrison",
        "service_type": "Garage Door Services",
        "tech_name": "Michael H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Abigail Patel",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Heather N.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Matthew Bell",
        "service_type": "Garage Door Installation",
        "tech_name": "Elizabeth S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Brian White",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joan S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Angela Bell",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kaitlyn H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Shelly Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "David M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Margaret Curry",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amy H.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "Michael Riley",
        "service_type": "Garage Door Services",
        "tech_name": "Molly D.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Andrew Wright",
        "service_type": "Garage Door Opener",
        "tech_name": "Cody S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Christopher Lozano",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Eric P.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Tracey Gregory",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jesse O.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Lisa Morgan",
        "service_type": "Garage Door Repair",
        "tech_name": "Michael B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "William Gray",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sabrina E.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Carol Simmons",
        "service_type": "Garage Door Off Track",
        "tech_name": "Douglas H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Eduardo Hubbard",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Allison S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Jennifer Townsend",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Mark H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Monica Vazquez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Melissa L.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Linda Williams",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Robin M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Nicholas Garrison",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Allison H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Emily Montoya",
        "service_type": "Garage Door Services",
        "tech_name": "Joy R.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Johnny Robinson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mark B.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Brett Campbell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Denise M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Terry Zamora",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Monica J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Rebecca Vincent",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Carl J.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Valerie Lowe",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Alan G.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Linda Willis",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Gabriel K.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Barbara Terrell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "David M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.8,
        "customer_name": "Sandra Bush",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "David R.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Jennifer Pace",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Daisy H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Ashley King",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jeremy C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Anthony Moreno",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "William J.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Joe Mann",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Todd H.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "Nicholas Munoz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Adam C.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Kenneth Rice",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Wendy P.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Jonathan Ross",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Colleen R.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Joseph Chambers",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Richard D.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "Daniel Reid",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Daniel H.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Rebecca Chung",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "John K.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-18",
        "review_rate": 5.0,
        "customer_name": "Stephen Eaton",
        "service_type": "Garage Door Insulation",
        "tech_name": "Amanda J.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Allen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Terri M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Anthony Gilmore",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Theresa H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Alexander Cruz",
        "service_type": "Garage Door Installation",
        "tech_name": "Chelsea B.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Paul Douglas",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Zachary W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "Scott Lynch",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amanda C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Roy Butler",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Roy L.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Vincent Holmes",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tammy W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Cody Mann",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nathan E.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Christopher Sanchez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joanne L.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Melissa Fleming",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jordan C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.8,
        "customer_name": "Michelle Gibbs",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Elizabeth H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-21",
        "review_rate": 5.0,
        "customer_name": "Diana Fernandez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Emily G.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Thomas Kennedy",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Diane W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Angela Gibson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Daniel B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Kelly Wolfe",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael A.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Karen Gardner",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael D.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Kevin Baldwin",
        "service_type": "Garage Door Insulation",
        "tech_name": "Logan S.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Debra Flores",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Justin R.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Grant Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kristi J.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "Jimmy Weeks",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jeffrey P.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "Jordan Ruiz",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Annette M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "Patrick Mcintyre",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Alexa H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Angela Brown",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Matthew S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Tanya Santiago",
        "service_type": "Garage Door Insulation",
        "tech_name": "Robin R.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "Andrew Dunn",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Scott B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Donna Daniels",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Stephanie B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Cody Alexander",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Louis H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Lisa Tyler",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Andrew S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Tanya Lowery",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robert S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Gabriel Casey",
        "service_type": "Garage Door Off Track",
        "tech_name": "Karen J.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "Danielle Vasquez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mary M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Shannon Arnold",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tiffany E.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Tanner Dunn",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Curtis T.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Mary Gonzalez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Joanna N.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Tina Henderson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sherry M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Lori Kim",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ashley M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Monica Dalton",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Craig C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.8,
        "customer_name": "Richard Woods",
        "service_type": "Garage Door Services",
        "tech_name": "Louis G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.8,
        "customer_name": "Mr. Brian Boyle",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Leah J.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Kenneth Valenzuela",
        "service_type": "Garage Door Installation",
        "tech_name": "Linda R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Charles Hernandez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kimberly G.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Jennifer Alvarez",
        "service_type": "Garage Door Installation",
        "tech_name": "David W.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Christian Lewis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Patricia J.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Brian Conner",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Anthony W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Adam Goodman",
        "service_type": "Garage Door Opener",
        "tech_name": "Angela B.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Cynthia Gonzalez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "James S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Jeremy White",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Alan A.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Heather Henderson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Rhonda H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Evelyn Santiago",
        "service_type": "Garage Door Installation",
        "tech_name": "Bradley M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Alice Powell",
        "service_type": "Garage Door Services",
        "tech_name": "Julian C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Annette Hernandez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sharon O.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Jared Rosales",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Chase M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Antonio Sanchez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Linda C.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "David Ellis",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Levi M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Dr. John Goodwin MD",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Carol A.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Carlos Brandt",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Lindsey P.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Amber Moore",
        "service_type": "Garage Door Opener",
        "tech_name": "Emily H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.8,
        "customer_name": "Lisa Schmitt",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jacob P.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "William Merritt",
        "service_type": "Garage Door Off Track",
        "tech_name": "Bonnie W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Susan Hensley",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Anthony W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Kevin Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Megan W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Beth Freeman",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jeffrey H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Stewart",
        "service_type": "Garage Door Repair",
        "tech_name": "Kristine H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Shawn Wu",
        "service_type": "Garage Door Services",
        "tech_name": "John J.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "John Faulkner",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Richard L.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "David Oconnell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "John N.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "George Butler",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Troy O.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Mr. Joshua Lee MD",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Hannah M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Charles Avila",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jennifer G.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Daniel Myers",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "David H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Victoria Jacobson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jonathan W.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Mr. Todd Jones",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Timothy C.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Robert Parks",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sean J.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Frederick Campbell",
        "service_type": "Garage Door Opener",
        "tech_name": "Joshua N.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Gabriel Fox",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christian G.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Daniel Kim",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Alexa A.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.8,
        "customer_name": "Brandy Hernandez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amanda A.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "Nathan Weiss",
        "service_type": "Garage Door Insulation",
        "tech_name": "Maria S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "William Silva",
        "service_type": "Garage Door Off Track",
        "tech_name": "Johnny W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Strong",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jaime H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Sara Small",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sandra E.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "Jason Hamilton",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sarah S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-14",
        "review_rate": 5.0,
        "customer_name": "Darren Moore",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Bradley J.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Brandi Nichols",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Mark P.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Mrs. Christina Franklin",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jill P.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Sheryl Aguirre",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jeff G.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Sherry Stuart",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sandra D.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Stephanie Gordon",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jeffrey B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Priscilla Weiss",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kimberly T.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Olivia Morris",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christina J.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Walter",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jennifer W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Miguel Lee",
        "service_type": "Garage Door Services",
        "tech_name": "Paul W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Dustin Johnson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Karina T.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Robert Lane",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Laura O.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "James Vazquez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jason E.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Karen Richardson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Robert B.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Joseph Bennett",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Anna P.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Angela Arnold",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Charlene M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Donna Jackson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Marcus H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Marie Villanueva",
        "service_type": "Garage Door Repair",
        "tech_name": "David H.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "Deborah Jacobs",
        "service_type": "Garage Door Off Track",
        "tech_name": "Alexander R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Jill James",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jenny C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Christopher Washington",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Andrew P.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Tiffany Valenzuela",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Willie B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Robert Solis",
        "service_type": "Garage Door Installation",
        "tech_name": "Eric K.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "Anthony Shaw",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nathaniel L.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Julia Garcia",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jessica T.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-14",
        "review_rate": 5.0,
        "customer_name": "Jeffery Reeves",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Judy S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.8,
        "customer_name": "Curtis Gross",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Regina R.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Holly Brown",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tamara C.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Ricardo Owen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Maria R.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Debra Walsh",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jack C.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Nathan Santana",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michele B.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Laura Frank",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "John M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Andrea Werner",
        "service_type": "Garage Door Off Track",
        "tech_name": "April D.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Danielle Sheppard",
        "service_type": "Garage Door Repair",
        "tech_name": "Stephen A.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Christopher Grimes",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Cindy B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Brittany Ellis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Thomas M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.8,
        "customer_name": "Jessica Walker",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kristopher B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Michael Lawson II",
        "service_type": "Garage Door Services",
        "tech_name": "Roger F.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Linda Jones",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nicholas O.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Jared Holder",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Laura G.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Bonnie Meyer",
        "service_type": "Garage Door Installation",
        "tech_name": "Zachary F.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Jeremiah Butler",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Angela H.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "James Bonilla II",
        "service_type": "Residential Garage Door Services",
        "tech_name": "David S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.8,
        "customer_name": "Monica Long",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brandon C.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Gary Reyes",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Anthony C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Laura Blankenship",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Bryan W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Crystal Wiggins",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael K.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Megan Murray",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tracy H.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-17",
        "review_rate": 5.0,
        "customer_name": "Jason Burch",
        "service_type": "Garage Door Off Track",
        "tech_name": "Andrea C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Darlene Patterson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jonathan C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Paul Reynolds",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kimberly C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Brent Miller",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stephen D.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Jennifer Parks",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tristan B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Andrew Bryan",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Cindy A.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Frank Silva",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Marco B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Anita Buchanan",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Alexandria R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Curtis Johnson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Gregory R.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Megan Adams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Fernando R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Thomas Benson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "James P.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.8,
        "customer_name": "Sarah Smith MD",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "James M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Julia Salazar",
        "service_type": "Garage Door Installation",
        "tech_name": "Ryan C.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Jason Mullins",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Judy L.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Nicholas Love",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Robert H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Mrs. Sharon Ramirez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Alex H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "Laurie Russell",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Antonio W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Scott Stanley",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jason R.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "Charles Ramos",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lisa H.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.8,
        "customer_name": "Wendy Castillo",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nathan H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Andrew Cummings",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Christopher R.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "William Holmes",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Katie J.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-05",
        "review_rate": 5.0,
        "customer_name": "Johnny Ramsey",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jessica C.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Amber Wagner",
        "service_type": "Garage Door Services",
        "tech_name": "Robert C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Kayla Romero",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Natasha F.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Timothy Little",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Cynthia W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-21",
        "review_rate": 5.0,
        "customer_name": "Gerald Taylor",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jennifer W.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Christine Burton",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kevin B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.8,
        "customer_name": "Katie Miller",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Charles F.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Kevin Brown",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robert G.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Amber Brewer",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Stanley L.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Tony Young",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kelsey L.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Dawn Turner",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Charles C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Patricia Daniel",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Melissa M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Diane Simon",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Alexandra C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.8,
        "customer_name": "Rodney Smith",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tammy L.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Taylor Callahan",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jasmine C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Samantha Chandler",
        "service_type": "Garage Door Insulation",
        "tech_name": "Mary C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.8,
        "customer_name": "Daniel Smith",
        "service_type": "Garage Door Repair",
        "tech_name": "Juan W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Gilbert Baker",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Cathy S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Angela Bailey",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "William A.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Jim Beard",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Anne H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Lisa Coleman",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Bradley G.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Danielle Snyder",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Heather L.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.8,
        "customer_name": "Rachel Reilly",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael J.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Terri King",
        "service_type": "Garage Door Repair",
        "tech_name": "Patricia H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Melinda Potter",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Amy G.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Kimberly Green",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Melvin M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Charles Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Pedro M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Jared Robinson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sean H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Shannon Carrillo",
        "service_type": "Garage Door Insulation",
        "tech_name": "Alicia S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-12",
        "review_rate": 5.0,
        "customer_name": "Patricia Torres",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alex H.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Angelica Austin",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Stephanie B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Sharon Taylor",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jacob S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.8,
        "customer_name": "Stacey Martin",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Patrick H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Carol Jones",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Monica W.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Bartlett",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Dennis H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "John Rowe",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Cameron C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.8,
        "customer_name": "Aaron Washington",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ronald V.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Leah James",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christina H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "Rebecca Ferguson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Shelly E.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "James Mcconnell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kelly H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Donald Garcia",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Aaron B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.8,
        "customer_name": "Michael Colon",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Clayton R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Amber Schmidt",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lisa J.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Erik Tanner",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Philip B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Adam Jacobs",
        "service_type": "Garage Door Repair",
        "tech_name": "Raymond C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Melissa Tapia",
        "service_type": "Garage Door Services",
        "tech_name": "Betty M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Mary French DDS",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Leslie B.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Joyce Walsh",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christopher J.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Michelle Jenkins",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Daryl L.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Joseph Ruiz",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kayla S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Colleen Williams",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Charles C.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Gary Lewis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jason H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Emma Clayton",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kenneth S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Susan Martin",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Johnathan P.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-14",
        "review_rate": 5.0,
        "customer_name": "Michelle Barton",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Timothy P.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Caitlin Stewart",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Audrey B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Samantha Hardin",
        "service_type": "Garage Door Installation",
        "tech_name": "Andrea W.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Wesley Barber",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Gary C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.8,
        "customer_name": "William Garrett",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sarah P.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Thomas Vargas",
        "service_type": "Garage Door Repair",
        "tech_name": "Karen M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Leonard Anderson",
        "service_type": "Garage Door Services",
        "tech_name": "David M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.8,
        "customer_name": "Edward Hester",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Shannon V.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Rebecca Brown",
        "service_type": "Garage Door Repair",
        "tech_name": "Robert M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Mark Miles",
        "service_type": "Garage Door Opener",
        "tech_name": "Kathryn I.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "Victoria Cooke",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Luis M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-19",
        "review_rate": 5.0,
        "customer_name": "Adam Myers",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michelle C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Joshua Holland",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Eric W.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Robert Martin",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "David F.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Sean Chan",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kenneth S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-01",
        "review_rate": 5.0,
        "customer_name": "Amy Velazquez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lydia J.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Amy Holloway",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kevin R.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Donna Taylor",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brooke M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "Sherry Farrell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Stephanie A.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Rebecca Mcpherson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michelle R.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Kevin Acosta",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lisa M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.8,
        "customer_name": "Rebecca Craig",
        "service_type": "Garage Door Off Track",
        "tech_name": "Dana W.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Tyler Nichols",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Crystal H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Michael Vega",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Zachary K.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Ariel May",
        "service_type": "Garage Door Insulation",
        "tech_name": "Zachary M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Aaron Hall",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kimberly A.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Jennifer Anderson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Logan T.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-08",
        "review_rate": 5.0,
        "customer_name": "Jeremy Johnson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mary F.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Tyler Chavez",
        "service_type": "Garage Door Repair",
        "tech_name": "Devin P.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Steven Allen",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Matthew B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-09",
        "review_rate": 5.0,
        "customer_name": "Heather Lopez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Rebecca B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "William Schwartz",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Rita W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-17",
        "review_rate": 5.0,
        "customer_name": "Joshua Johnson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tyler S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Robert Ryan",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brandon P.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "Anthony Garcia",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Walter R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "Jessica Robinson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Cathy B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Regina Morse",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kevin H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Troy Ramirez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Steven P.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Steven White",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "William V.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "Ann Miller",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Shawn B.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Sean Barnett",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Deborah M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Alex Diaz",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Gabriel P.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Gregory Espinoza",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sandra P.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-04",
        "review_rate": 5.0,
        "customer_name": "Lucas Greene",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Connie S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Jacob Aguilar",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christina C.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Gary Sampson DDS",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Rodney C.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Tina Frazier",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Angela J.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-19",
        "review_rate": 5.0,
        "customer_name": "Matthew Johnson",
        "service_type": "Garage Door Services",
        "tech_name": "Patrick H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Jonathon Lopez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Robert M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Alexander Butler",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Daniel G.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.8,
        "customer_name": "Stephen Nelson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Nicholas C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Jennifer Carey",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kathryn M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Samuel Archer",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Nathaniel C.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Jacob Velez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michael R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Dennis Ramos MD",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brandon G.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Ashley Turner",
        "service_type": "Garage Door Opener",
        "tech_name": "Christopher J.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Robert Black",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Oscar A.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Stephanie Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Katie J.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Jennifer Keller",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Timothy R.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "James Wilson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kevin Y.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Rebekah Lopez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Joseph S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Gary Jackson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Proctor",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tamara S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Brian Craig",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Heidi H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Pamela Lewis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Thomas H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Joshua Wright",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Melissa J.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Nathan Kennedy",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michele M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Chad Hogan",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Cheyenne A.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Caroline Jones",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kenneth G.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Arthur Chavez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Calvin W.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Clark",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sharon W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Jennifer Patrick",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "David L.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Russell Hernandez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jessica S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Kyle Contreras",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Thomas M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Tamara Lowe",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Donna N.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Brian Smith",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Edward D.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Dr. Dana Brown MD",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brittney L.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Kevin Bauer",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nicholas M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Dustin Bailey",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Katie C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Jonathan Archer",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Richard W.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Adrienne Baker",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Richard A.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Jesse Hamilton",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brandi T.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Madison Thomas",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Rachel S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Jessica Willis",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Johnathan L.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Christy Little",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "April G.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Steve Ramos",
        "service_type": "Garage Door Services",
        "tech_name": "Kevin S.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-16",
        "review_rate": 5.0,
        "customer_name": "Keith Craig",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Joshua R.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Bethany Bates",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jacqueline T.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Christopher Sims",
        "service_type": "Garage Door Repair",
        "tech_name": "Margaret C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Stacey Benson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Robert F.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Joshua Hudson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Andrew M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Amanda Martinez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Olivia B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Kristen Ramirez MD",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Logan W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Paul Rice",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Julia M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "John Jones",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ryan Z.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Michelle Warren",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christian B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Melissa Barnett",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Benjamin S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.8,
        "customer_name": "Benjamin Hill",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jenna L.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Thomas Berg",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Laura F.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "Monica Lewis",
        "service_type": "Garage Door Insulation",
        "tech_name": "Frank W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Bryan Stevens",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Nicole P.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Jonathan Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Nicholas H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "James Roy",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sara R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Susan Simon",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Bob G.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Noah Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Gregory F.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-01",
        "review_rate": 5.0,
        "customer_name": "Nancy Carter",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Leslie P.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Jason Novak",
        "service_type": "Garage Door Services",
        "tech_name": "Kenneth G.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Samuel Hill",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Robert G.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Donna Martinez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Suzanne C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Walter Roberts",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Christopher P.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Matthew Miller",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christopher R.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.8,
        "customer_name": "Anthony Ward",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Theresa S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Ronald Choi",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sherry S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "John Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Melody C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-24",
        "review_rate": 5.0,
        "customer_name": "Madeline Hudson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nicole L.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Curtis Johns",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jeremy S.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Renee Allen",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Steven C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Ashley Lopez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Marcus W.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Jason Moon",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jose K.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Dillon Coleman",
        "service_type": "Garage Door Off Track",
        "tech_name": "Barbara D.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Deborah Hatfield",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jamie M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Bruce Love",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "John A.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-14",
        "review_rate": 5.0,
        "customer_name": "Patrick Thompson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Joshua B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-03",
        "review_rate": 5.0,
        "customer_name": "Brent Dean",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Steven S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Erin Nelson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jason L.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Kathy Hansen",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sean D.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Amanda White",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Danielle G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Suzanne Hall",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sheila M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Brent Lambert",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joseph R.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Jo Murray",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tina C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Brianna Scott",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Anthony R.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Gardner",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brandon B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Adam Hudson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ryan M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Joshua Lawson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ryan H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Brian Gibbs",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Patricia S.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "Kevin Hamilton",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Theresa R.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Sara Young",
        "service_type": "Garage Door Repair",
        "tech_name": "Javier M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Terry Potter",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brian M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Melissa Smith DDS",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Trevor P.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Michelle House",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Richard L.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.8,
        "customer_name": "Adam Mitchell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Gary D.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Deborah Hampton",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jeffrey L.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Jessica Andrews",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ashley R.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.8,
        "customer_name": "Andrew Hunt",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jerry J.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Mary Bailey",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lisa L.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Jasmine Johns",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Mark V.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "James Lowe",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Allen F.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Casey Jones",
        "service_type": "Garage Door Insulation",
        "tech_name": "Barbara C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.8,
        "customer_name": "Ralph Washington",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ashley G.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Thomas Banks",
        "service_type": "Garage Door Services",
        "tech_name": "Robert C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Erin Peterson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Savannah O.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "Heather Conway",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jessica S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Maria Ortega",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ashley C.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Taylor Peterson",
        "service_type": "Garage Door Services",
        "tech_name": "David L.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-27",
        "review_rate": 5.0,
        "customer_name": "Michael Davila",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nathan B.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Nicole West",
        "service_type": "Garage Door Opener",
        "tech_name": "Kimberly C.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Alexandria Chan",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Amanda W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Micheal Williams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Erik R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Daniel Campos",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Emily W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Christina Edwards",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Gina P.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Lisa Cole",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michelle B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "James Jackson",
        "service_type": "Garage Door Repair",
        "tech_name": "Joseph F.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Joseph Schultz",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Alexandra S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Jose Malone",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Joseph B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Michelle Atkins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Diana B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Daniel Pineda",
        "service_type": "Garage Door Installation",
        "tech_name": "Ashley W.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Nicholas Gallegos",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Pamela C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Ryan Blair",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Bryan A.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Michael Middleton",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Patrick C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "Aaron Martinez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Eric J.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Kenneth Wade",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Cassandra M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Peter Lopez",
        "service_type": "Garage Door Installation",
        "tech_name": "Laura H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Caitlin Avila",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher J.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Karen Christian",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Clifford D.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Joseph Dickerson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Debra C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-18",
        "review_rate": 5.0,
        "customer_name": "Adam Price",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Theresa O.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "Christopher Day",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Keith P.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Richard Williams",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Curtis O.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Patricia Richardson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Edgar S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "Stacey Nelson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Timothy C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Kimberly Butler",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sandra A.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Monica Carson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Sara Jones",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Bryan H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Joseph Ryan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Robert M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Dawn Huang",
        "service_type": "Garage Door Installation",
        "tech_name": "Jennifer C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Susan Good",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kathleen N.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "Stephanie Howard",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Richard B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Hector Morgan",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "David H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Eric Macdonald",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher O.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Robert Sutton",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Renee H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Jennifer Roberts",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joseph B.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Jessica York",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Gary S.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Mr. Peter Hoffman DDS",
        "service_type": "Garage Door Opener",
        "tech_name": "Jessica D.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Timothy Wilson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Cynthia H.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Christine Green",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Derek M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Tanya Phillips",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Diana P.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "James Rollins",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Theresa H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Erin Simpson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jeremy J.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Morgan Hansen",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kelly O.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Donald Collins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Peter C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Megan Ochoa",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Sarah D.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Brendan Wallace",
        "service_type": "Garage Door Services",
        "tech_name": "Ross Z.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-19",
        "review_rate": 5.0,
        "customer_name": "Brian Wells",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Daniel C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Mason Cruz",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tammy A.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "James Good",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Regina F.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "Sandra Roberts",
        "service_type": "Garage Door Repair",
        "tech_name": "Stephen T.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Jason Scott",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kelsey B.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.8,
        "customer_name": "Daniel Khan",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Janice V.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Patrick Martin",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stacey L.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Danielle Smith",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Devin D.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Joshua Mclean",
        "service_type": "Garage Door Installation",
        "tech_name": "Paul O.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.8,
        "customer_name": "Jaime Sanchez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Joseph L.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-12",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Sanchez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Eric T.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Melissa Bell",
        "service_type": "Garage Door Installation",
        "tech_name": "Christopher M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Stephanie Jones",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kevin T.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Cody Webb",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christina M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Ryan Ashley",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Roberta G.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Ryan Petersen",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ashley W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Jay Hines",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Victor W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Kimberly Jackson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Shannon N.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Charles Robles",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Paula A.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Jonathan Moreno",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ryan H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Maria Dunn",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jared R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Joan Bass",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Hannah G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Michael Hernandez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sabrina P.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "Natasha Stevenson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "David W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Brandy Thomas",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kyle Y.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.8,
        "customer_name": "Kim Carson",
        "service_type": "Garage Door Services",
        "tech_name": "Karen D.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "Debra Cabrera",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Daisy G.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-25",
        "review_rate": 5.0,
        "customer_name": "Melissa Nelson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jacqueline V.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.8,
        "customer_name": "Paul Gonzalez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mark K.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Jessica Webster",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tara P.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Amy Lopez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kevin W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Katie Tapia",
        "service_type": "Garage Door Off Track",
        "tech_name": "William V.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Reginald Davis",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kelly J.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "Maria Taylor",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Gabriel A.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Alex Glenn",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Douglas C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "Eugene Rios",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jennifer S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.8,
        "customer_name": "Ana Lopez",
        "service_type": "Garage Door Installation",
        "tech_name": "Haley T.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Emily Dorsey",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christian C.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Keith Frye",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Danny W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Christina Gaines",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jonathan S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-29",
        "review_rate": 5.0,
        "customer_name": "Mark Young",
        "service_type": "Garage Door Services",
        "tech_name": "Brandy O.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Mark Thomas",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Bobby Williams",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jason W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Caroline Ellison",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joe G.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Daniel Crawford",
        "service_type": "Garage Door Off Track",
        "tech_name": "Makayla H.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "James Hill",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ruben G.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "Melissa Davis",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michael B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Andrew Sanders",
        "service_type": "Garage Door Insulation",
        "tech_name": "Elizabeth S.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.8,
        "customer_name": "Patricia Ford",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Edward K.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Scott Carter",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joseph W.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Lauren Chaney",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Janet R.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Antonio Patterson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christina M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Dominic Gutierrez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jacob P.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "David Miller",
        "service_type": "Garage Door Installation",
        "tech_name": "Jacqueline S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Michael Phillips",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Keith T.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Travis Cole",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jessica Z.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "Laura King",
        "service_type": "Garage Door Opener",
        "tech_name": "April C.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.8,
        "customer_name": "Veronica Evans",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Leslie E.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Mrs. Margaret Schmidt",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Diana C.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Jake Gray",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "James P.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-07",
        "review_rate": 5.0,
        "customer_name": "Debra Jackson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Rebecca B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Lisa Olsen",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jesse C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.8,
        "customer_name": "Eddie Jones",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Paul S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Jennifer Bush",
        "service_type": "Garage Door Off Track",
        "tech_name": "Amanda R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Tanya Harrison",
        "service_type": "Garage Door Opener",
        "tech_name": "Diana M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "Alyssa Curtis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Erin M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Jessica Obrien",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Emma F.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Nicole Thompson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Nancy B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Jamie Martin",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ryan C.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Jacob Frye",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robert M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Emily Jones",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sarah G.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "George Riddle",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Justin M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "Kristen Gray",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brianna F.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Rebekah Montgomery",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Matthew F.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Claire Cross",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jeremiah C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Andrew White",
        "service_type": "Garage Door Services",
        "tech_name": "Joshua D.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Cynthia Gates",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jessica C.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Isabel Weber",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael A.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Robert Maldonado",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Larry H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Mary Weber",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Shannon C.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Anthony Davis",
        "service_type": "Garage Door Opener",
        "tech_name": "Ian D.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Chris Franco",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christina E.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Thomas Potter",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Gerald W.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "James Duran",
        "service_type": "Garage Door Services",
        "tech_name": "Edward J.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Anthony Hansen",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brittney G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Adriana Salinas",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Gilbert S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.8,
        "customer_name": "Bethany Arnold",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Scott A.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Ashley Smith",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tanner H.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Keith Garcia",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tracy H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Craig Little",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael E.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Erica Todd",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jessica H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Brian Jackson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lynn W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Emily Miller",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lisa W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Carla Elliott",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Yolanda H.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.8,
        "customer_name": "Robert Jones",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Donna R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Sharon Jordan",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Mary C.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Brittany Barnes",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Shane S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-14",
        "review_rate": 5.0,
        "customer_name": "Kevin Harrington",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nicholas W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Courtney Caldwell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Casey K.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Michael Morales",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kristin L.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Timothy Jordan",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sally P.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Samantha Weber",
        "service_type": "Custom Garage Door Design",
        "tech_name": "John J.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Vickie Lloyd",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jason H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-28",
        "review_rate": 5.0,
        "customer_name": "Angel Yates",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Anthony W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "John Carter",
        "service_type": "Garage Door Services",
        "tech_name": "David B.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Tamara Lee",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christopher L.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Marissa Ortiz",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Patrick R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.8,
        "customer_name": "Todd Barajas",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Linda F.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-09",
        "review_rate": 5.0,
        "customer_name": "Jennifer Coleman",
        "service_type": "Garage Door Off Track",
        "tech_name": "Joseph L.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Daniel Schmidt",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tiffany C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Cassandra Harris",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Mark P.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.8,
        "customer_name": "Jennifer Davis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Nicole G.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-22",
        "review_rate": 5.0,
        "customer_name": "Kevin Brennan",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Gregory B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Marie Gonzalez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Thomas C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Jamie Hatfield",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Rickey J.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Kayla Lopez DDS",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Antonio M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Lee Meyer",
        "service_type": "Garage Door Off Track",
        "tech_name": "Allison C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Cody Hamilton",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jennifer T.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Meagan Parker",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jessica A.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Christie Schultz",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Erika B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Paul Ritter",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kevin S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Renee Morgan",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Susan L.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.8,
        "customer_name": "Dean Carter",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christopher E.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "April Gallagher",
        "service_type": "Garage Door Services",
        "tech_name": "Manuel K.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Brian Harrington",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Robert S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Mary Moyer",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Wendy W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Sara Sanchez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Angela C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Kenneth Joseph",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Donna M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Nicole Silva",
        "service_type": "Garage Door Insulation",
        "tech_name": "Dustin R.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "Cody Nash",
        "service_type": "Garage Door Maintenance",
        "tech_name": "William J.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Luis Watson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Robert W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Jonathan Hickman",
        "service_type": "Garage Door Insulation",
        "tech_name": "Katie T.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-22",
        "review_rate": 5.0,
        "customer_name": "Jason Rangel",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Justin H.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Kristen Murphy",
        "service_type": "Garage Door Insulation",
        "tech_name": "Robert A.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Pamela Harris",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Richard G.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Guy Jones",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Melissa C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "Nancy Sanders",
        "service_type": "Residential Garage Door Services",
        "tech_name": "William K.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Angela Hess",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kenneth F.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Brandi Wyatt",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Christine R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Tara Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joyce S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Linda Turner",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dwayne B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Amy Harris",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Abigail B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Evelyn Hayes",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "David C.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Kimberly Ray",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Chad M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Monica Clark",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jose B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Desiree Evans",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ariel M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Lisa Smith",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jill S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Terry Davidson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kathryn M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.8,
        "customer_name": "Stacy Barnett",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Julie S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Brittany Hamilton",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Douglas A.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "James Smith",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Timothy S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Bryan Buckley",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Monica R.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Denise Goodman",
        "service_type": "Garage Door Opener",
        "tech_name": "Charlene M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Rachel Krueger",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sherry N.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Keith Mitchell",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kelly C.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Joseph Lee",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Timothy D.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.8,
        "customer_name": "Sarah Sanford",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Alisha W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Bradley Schneider",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Stephanie O.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Angela Foster",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Rhonda B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Claudia Carter",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Gary B.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Mark Jimenez",
        "service_type": "Garage Door Installation",
        "tech_name": "Denise S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Pedro Michael",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Phillip H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Edward Wallace",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Anna J.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Janice Harris",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brian B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Donald Vega",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Amy M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "James Gutierrez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Curtis S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "Rebecca Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Edward H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Samantha Brown",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Timothy W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Joel Roman",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jonathan J.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Shirley Sims",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Robert S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Rebecca Mathews",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Charles B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Daniel Johnson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tina M.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Juan Jones",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jacqueline T.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Kayla Wilson",
        "service_type": "Garage Door Installation",
        "tech_name": "Stephanie C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Sherry Flores",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Emily A.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.8,
        "customer_name": "William Curtis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Shannon H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Ryan Steele",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Gregory S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-12",
        "review_rate": 5.0,
        "customer_name": "Jason Bradford",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael C.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "Patrick Thomas",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jeffrey A.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Kevin Hernandez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Alexander W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Paige Garcia",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Seth W.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Wesley Simpson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Robert B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Thomas Owens",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lisa H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Willie Randall",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sherry R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Natalie Perez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Zachary O.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Shane Navarro",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "James P.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.8,
        "customer_name": "Spencer Turner",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kathryn T.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.8,
        "customer_name": "Timothy Lane",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Hannah S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.8,
        "customer_name": "Ann Medina",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "John M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "William Brooks",
        "service_type": "Garage Door Installation",
        "tech_name": "Ronnie C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Trevor Ray",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jennifer C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Frank Evans",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jennifer H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-01",
        "review_rate": 5.0,
        "customer_name": "Jennifer Carpenter",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Shannon M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Ashley Jones",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Veronica H.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Sabrina Marshall",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Julie J.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Joyce Burton",
        "service_type": "Garage Door Services",
        "tech_name": "Jeffrey W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Benjamin Dyer",
        "service_type": "Garage Door Insulation",
        "tech_name": "Craig T.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.8,
        "customer_name": "Dr. Robert White",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christopher R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Ryan Bowman",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Valerie P.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.8,
        "customer_name": "Heather Garrett",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Rose R.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Ashley Snow",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Adam G.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Sandra Tran",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Angela G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Alyssa Madden",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Daniel B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Kyle Lynch",
        "service_type": "Garage Door Maintenance",
        "tech_name": "James J.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Justin Long",
        "service_type": "Garage Door Repair",
        "tech_name": "Veronica N.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Melissa Miller",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alexis T.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Jenny Christian",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Evan K.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Emily Gonzalez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brian R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Jones MD",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Matthew N.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "David Davis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Pamela W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Jasmine Walls",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kaitlyn H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Amanda Oconnor",
        "service_type": "Garage Door Opener",
        "tech_name": "Amy W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Hannah Cohen DDS",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brandon M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Nicholas Galloway",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Hannah T.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Amber Pratt",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kimberly C.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Charlene Owens",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Mary W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "John Wilson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Laura P.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Donna Pruitt",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christopher M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-24",
        "review_rate": 5.0,
        "customer_name": "Charles Gross",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "James B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Mary Jackson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Lauren M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Jeffery Campbell",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael N.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Christopher Yates",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Joseph W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "Douglas Thomas",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Caroline M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Jessica Miller",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lori T.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Dominic Wise",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Gary J.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Jennifer Larsen",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "David C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-26",
        "review_rate": 5.0,
        "customer_name": "Ricky Bird",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kimberly J.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-06",
        "review_rate": 5.0,
        "customer_name": "Henry Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Emily C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Ricky Clay",
        "service_type": "Garage Door Insulation",
        "tech_name": "Julie A.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Darryl Kelly",
        "service_type": "Garage Door Insulation",
        "tech_name": "Patrick J.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Hannah Jones",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "William B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Todd Cooper",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Wendy A.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Tonya Richardson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Adam T.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Deborah Lopez",
        "service_type": "Garage Door Installation",
        "tech_name": "Anthony M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Stephanie Heath",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jessica G.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Deborah Simmons",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Susan S.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Amanda Smith",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Yvonne B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "William Hughes",
        "service_type": "Garage Door Off Track",
        "tech_name": "Stacy R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-29",
        "review_rate": 5.0,
        "customer_name": "Brenda Andrews",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Donald B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Courtney Gonzalez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Gregory L.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Jesse Martinez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Ryan S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.8,
        "customer_name": "Paul Beasley",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jennifer S.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Mary Newton",
        "service_type": "Garage Door Opener",
        "tech_name": "Michele M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Jessica Boyle",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Patricia W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Kathleen Casey",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christopher L.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Kim Fletcher",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Dawn M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Carl Solis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Timothy W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Tabitha Alvarez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Pam R.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Kimberly Greene",
        "service_type": "Garage Door Services",
        "tech_name": "Nathan P.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Brian Hanson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Janet S.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Darrell White",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jill H.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Eric Barton",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Paula M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Christy Davis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Dana M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Kelly Joyce",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christopher M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Erin Barr",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jessica K.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Eric Meyers",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joseph O.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.8,
        "customer_name": "Frederick Hatfield",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Alyssa L.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.8,
        "customer_name": "Jessica Gray",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Matthew S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Anne Farmer",
        "service_type": "Garage Door Installation",
        "tech_name": "Melissa P.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Brian Rhodes",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Nicole B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Owens",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Thomas M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Sally Avila",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Randy L.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Richard Clayton",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Valerie S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.8,
        "customer_name": "Angela Brown",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Susan P.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "Stephanie Franklin",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Casey B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Kenneth Marshall",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Megan M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Alexandria Jones",
        "service_type": "Garage Door Installation",
        "tech_name": "Kevin W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "Robert Rogers",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tommy R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Anthony Pena",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kyle J.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Diane Mcbride",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nicholas S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Jose Ryan",
        "service_type": "Garage Door Opener",
        "tech_name": "Lee B.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Mark Sharp",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Steven E.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Jody Carlson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Daniel P.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.8,
        "customer_name": "Richard Mcpherson",
        "service_type": "Garage Door Repair",
        "tech_name": "Terri S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "William Hughes",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brenda R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Alvin Cunningham",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Heidi D.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.8,
        "customer_name": "Pamela Evans",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jeremy H.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-05",
        "review_rate": 5.0,
        "customer_name": "Paul Cox",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michael W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Richard Morrow",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Shelley W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Tracy Garza",
        "service_type": "Garage Door Installation",
        "tech_name": "Nicholas R.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Kylie Walsh",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Amy M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Christopher Bowen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kristen S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Jose Obrien",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Andrew J.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Amy Brown",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Anthony R.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Sharon Harris",
        "service_type": "Garage Door Insulation",
        "tech_name": "Monica B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "Melissa Cole",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Stephanie Lane",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Elizabeth K.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Jennifer Shea",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jenny C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Willie Walters",
        "service_type": "Garage Door Insulation",
        "tech_name": "Todd C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Kaitlyn Evans",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Samantha R.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Gregory Keller",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Frances K.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Lori Thomas",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Dana D.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Chad Jordan",
        "service_type": "Garage Door Installation",
        "tech_name": "Carlos W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Samantha Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jacqueline F.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.8,
        "customer_name": "Jerry Mcneil",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ryan O.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.8,
        "customer_name": "Julie Rivera",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Valerie L.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.8,
        "customer_name": "Jacob Williams",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Casey M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Dylan Dominguez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Madeline K.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-09",
        "review_rate": 5.0,
        "customer_name": "Allison Tyler",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jesse S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Diana Gay",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lori H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.8,
        "customer_name": "Jeffery Wise",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Teresa D.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Dr. Donald James MD",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Chris K.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Mark Walsh",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Robert J.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Brett Perry",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kelly O.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Alexander Long",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Annette G.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.8,
        "customer_name": "Wendy Wood",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Joshua P.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Philip Parrish",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joanne S.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Michael Mcclain",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jacob L.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Cynthia Stein",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kathleen T.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Robert Reed",
        "service_type": "Garage Door Repair",
        "tech_name": "Tracy J.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Joseph Harris",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Patricia W.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Heather Miller DDS",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Donna M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Kristen Morris",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Laura P.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.8,
        "customer_name": "Nancy Mercer",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jennifer G.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "David Robinson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mark R.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Karen Everett",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Troy S.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Bridget Mitchell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Troy S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Terrence Wyatt",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Adam M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Melissa Soto",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Tracy R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "Kari Arnold",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "David R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Felicia Jones",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Nicholas J.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Eric Turner",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "David G.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Tara Benitez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sara T.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Andrea Gonzales",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Erika R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Jeffery Hernandez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Regina O.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.8,
        "customer_name": "Michael Pearson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tommy R.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Sandra Powers",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Douglas B.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Jennifer Gates",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Bryan W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Derek Brown",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Charles M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "Matthew Brown",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "James R.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "Rebecca Nunez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Angela G.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Tracy Dean",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Amy D.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Vincent Davidson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Maxwell J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Julian Adams",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Samantha M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Boyd",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Theresa G.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.8,
        "customer_name": "Marc Lane",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Thomas L.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Wendy Zimmerman",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lisa F.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Lori King",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Richard P.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Cody Rosario",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "William S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Karen Miller",
        "service_type": "Garage Door Repair",
        "tech_name": "Sarah R.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.8,
        "customer_name": "Robert Booth",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kurt K.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Ashley Carter",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Heather A.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Wendy Cordova",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michael B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Brandon Krause",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lucas S.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-29",
        "review_rate": 5.0,
        "customer_name": "Chase Johnson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Danielle O.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Jack Romero",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Dana P.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Julie Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jesse M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Timothy Parks",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ryan L.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Angela Herman",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sarah P.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Lance Cain",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Andrew L.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Anthony Villarreal",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Meredith Drake",
        "service_type": "Garage Door Track Repair",
        "tech_name": "John W.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.8,
        "customer_name": "Kristina Baker",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Deborah M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Vincent Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Meghan J.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Katherine Davis",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Trevor A.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Larry Livingston",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "James S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "William Myers",
        "service_type": "Garage Door Opener",
        "tech_name": "James M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Mrs. Pamela Hoffman",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Russell S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Tristan Tate",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Julie G.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Tabitha Rodriguez",
        "service_type": "Garage Door Repair",
        "tech_name": "Amy R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Michael Cruz",
        "service_type": "Garage Door Insulation",
        "tech_name": "Cristina S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Veronica Carter",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amy H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Monica Michael",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Steven D.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Sarah Mack",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sandra W.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Steven Jones",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Robin S.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Joseph Gutierrez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Alejandro W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Renee Bell",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brenda R.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-07",
        "review_rate": 5.0,
        "customer_name": "Jill Avila",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Vincent T.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Parker",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lisa J.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Nicholas Clark",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Adam B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Katherine Wood",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sarah T.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Mary Brady",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "William D.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.8,
        "customer_name": "Anthony Vang",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lori J.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Jillian Tanner",
        "service_type": "Garage Door Installation",
        "tech_name": "Mark H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Robert Coleman",
        "service_type": "Garage Door Services",
        "tech_name": "Mark T.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Nicholas Hubbard",
        "service_type": "Garage Door Opener",
        "tech_name": "James B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Jordan Andrade",
        "service_type": "Garage Door Repair",
        "tech_name": "Nicole M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-19",
        "review_rate": 5.0,
        "customer_name": "Tanya Rivera",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Stephanie H.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.8,
        "customer_name": "Tiffany Brown",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Janet S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Charles Harding",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Joseph D.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Stacy Scott",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Keith G.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.8,
        "customer_name": "Theodore Hill",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Rebecca M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Christy Henderson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Anthony P.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Kimberly Chavez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christopher L.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Kyle Garrett",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Shannon L.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Rose Marks",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kaitlyn S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-09",
        "review_rate": 5.0,
        "customer_name": "Robert Burns",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kayla C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Kelly Adams",
        "service_type": "Garage Door Opener",
        "tech_name": "Daniel P.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "Dr. Jessica Navarro",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Anthony H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Barry Norris",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Dennis C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "Tommy Curtis",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Elizabeth B.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Brian Watkins",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Stephanie B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Derrick Thompson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Margaret P.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "Lisa Terry",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Justin M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-03",
        "review_rate": 5.0,
        "customer_name": "Jonathan Jones",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sean D.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-10",
        "review_rate": 5.0,
        "customer_name": "Sabrina House",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Melissa H.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Nicole Brewer",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jason K.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Andrew Wolfe",
        "service_type": "Garage Door Services",
        "tech_name": "Robert C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-30",
        "review_rate": 5.0,
        "customer_name": "Joshua Johnson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Matthew M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Leslie Perez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Daniel A.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Ryan Diaz",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brenda B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Julie Parsons",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Craig H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Johnny Stewart",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Marissa V.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Samuel Hill",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robert C.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Maria Doyle",
        "service_type": "Garage Door Off Track",
        "tech_name": "Nathan H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "James Green",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kimberly L.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Amanda Boone",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Amanda J.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "Kathleen Wilson",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael N.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "Derrick Wilson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Trevor R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Carrie Ward",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Stephanie S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Breanna Johnson",
        "service_type": "Garage Door Repair",
        "tech_name": "Carol T.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Candice Avila",
        "service_type": "Garage Door Services",
        "tech_name": "Gabrielle A.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Sean James",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Alison B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Ms. Sandra Hudson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jeffrey C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Amanda Moore",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Heidi T.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Tammy Francis",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mark L.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-24",
        "review_rate": 5.0,
        "customer_name": "Erin Wagner",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael J.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Jason Elliott",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Karen R.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Caitlin Wyatt",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Stephanie V.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.8,
        "customer_name": "Robert Mathis",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Pamela S.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Juan Johnson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Adam N.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Christopher Rivers",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Joanna O.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Mr. Bruce Price",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "John W.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Brendan Fisher",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Dave R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Lisa Barajas",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kimberly M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-26",
        "review_rate": 5.0,
        "customer_name": "Francis Thompson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Patrick M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Paul King",
        "service_type": "Garage Door Repair",
        "tech_name": "Patrick P.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Mia Bryant",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Steven Y.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Kelli Pittman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Karen K.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-23",
        "review_rate": 5.0,
        "customer_name": "Jeremiah Parker",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brian K.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "John Durham",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Debbie S.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Deanna Patel",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Xavier G.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Brian Mann",
        "service_type": "Garage Door Installation",
        "tech_name": "Lisa H.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "Claire Ryan",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nathan J.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Linda Gregory",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tommy M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Gardner",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Melissa W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Kristine Franklin",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kirsten A.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "Alex West",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Eric G.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Dana Parker",
        "service_type": "Garage Door Services",
        "tech_name": "Elizabeth T.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Andrew Ritter",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Erica R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Alex Richardson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Marc W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "William Hubbard",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joseph B.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Megan Fisher",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Daniel C.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Jamie Fitzpatrick",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Emily M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Patrick Thompson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Krista M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Rhonda Bryant",
        "service_type": "Garage Door Services",
        "tech_name": "Daniel B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Jill Robinson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brittany H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Kelly Meyers",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tiffany M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.8,
        "customer_name": "Dr. Eric Rivas",
        "service_type": "Garage Door Services",
        "tech_name": "Brian H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Tonya Shaw",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joshua G.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "Derrick Hooper",
        "service_type": "Garage Door Off Track",
        "tech_name": "Anna A.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Karen Johnston",
        "service_type": "Garage Door Services",
        "tech_name": "Samantha P.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Cody Cain",
        "service_type": "Garage Door Opener",
        "tech_name": "Linda H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "Michelle Perez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robin R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Jenna Weiss",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jonathan G.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Jason Hall",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Andrew B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Neil Hudson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sarah C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Tonya King",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jessica W.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Tanner Gomez",
        "service_type": "Garage Door Services",
        "tech_name": "John R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Herbert Sanchez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jessica D.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Stephanie Cox",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Joshua S.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Michaela Carr",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Gabrielle A.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Barbara Frost MD",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brooke C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Christopher Walters Jr.",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Leslie T.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Jodi Palmer",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Victoria M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Kelly Coleman",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher J.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Dustin Cline",
        "service_type": "Garage Door Insulation",
        "tech_name": "Erik T.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "David Collins",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Taylor C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Gary Horn",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kevin W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Todd Williams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Breanna H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Stephen Decker",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Nancy N.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Steve Williams",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Isabel T.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Hopkins",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Donna D.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Ellen Nichols",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Robin L.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Casey Garza",
        "service_type": "Garage Door Off Track",
        "tech_name": "Eric P.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Ashley Galvan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Glenda S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Rebecca Valdez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Gary R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Williams",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kristin E.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Amber Rocha",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Roger J.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Laura Mason",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sheila C.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Peter Rice MD",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Frederick M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Stephanie Fuentes MD",
        "service_type": "Garage Door Opener",
        "tech_name": "Ashley G.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Jose Davenport",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Alan B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Thomas Joseph",
        "service_type": "Garage Door Insulation",
        "tech_name": "Christopher P.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Jessica Peterson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Mary M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Jason Frazier IV",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael J.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "William Owens",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Darryl H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "Robert Gutierrez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kevin A.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Andrea Lloyd",
        "service_type": "Garage Door Off Track",
        "tech_name": "Edward A.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Rachel Lopez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Cody W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Raymond Hudson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christopher C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Amy Clark",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Samantha P.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Sarah Byrd",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tracy C.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Patrick Curry",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ruben A.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "James Lewis",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Misty J.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Kevin Hill",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Deanna H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Angela Brown",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kevin L.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Elaine Jackson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jennifer G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Heidi Richardson",
        "service_type": "Garage Door Services",
        "tech_name": "Gregory J.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Ms. Shannon Martinez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lori M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.8,
        "customer_name": "Victoria Harvey",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tamara D.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Monica White",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Matthew P.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Alexander Gibson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Henry G.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Michael Bernard",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Robert F.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Oscar Johnson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Carl D.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Peter Garcia",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "David T.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Kevin Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Hayley H.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Lauren Cox",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kelsey C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Joseph George",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jeremy D.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.8,
        "customer_name": "Jordan Cooper",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Arthur J.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Ashley King",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Samantha H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.8,
        "customer_name": "Alan Perez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sandra E.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Frank Guerrero",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael P.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Megan Mckee",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christopher E.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.8,
        "customer_name": "Bruce Davidson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kathy R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Michael Williams",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.8,
        "customer_name": "Julia Snyder",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michelle S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-26",
        "review_rate": 5.0,
        "customer_name": "Michael Coleman",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Howard D.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Jonathan Jackson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tammy M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "Albert Boone",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ellen C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-09",
        "review_rate": 5.0,
        "customer_name": "Brett Ellis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tammy P.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Alex Gonzalez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Craig S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Andrew Scott",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joshua L.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Sharon Mcknight",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Crystal A.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Pamela Macias",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brian N.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "James Rodriguez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Grace M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Christopher Vega",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kaitlyn C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "Christina Meyer",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kristin B.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Kevin Martin",
        "service_type": "Garage Door Installation",
        "tech_name": "Mary R.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Samuel Sims",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Derrick N.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Theresa Gibbs",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Daryl C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Crystal Rangel",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Megan G.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "Erik Mcgee",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Matthew R.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "James Mercer",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Lindsey B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Mikayla Dominguez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Holly B.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Sheri Daniel",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Robert H.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Laurie Davis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "David W.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Alyssa Ortiz",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sara C.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Jennifer Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amanda M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "Anthony Welch",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sean R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Donna Lopez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Edward B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Robert Morris",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Justin F.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-13",
        "review_rate": 5.0,
        "customer_name": "Michelle Roberts",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Laura T.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "William Andrews",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rebekah B.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-31",
        "review_rate": 5.0,
        "customer_name": "Charles Christian",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kenneth M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Kathleen Rogers",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kimberly D.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "Mariah Harris",
        "service_type": "Garage Door Insulation",
        "tech_name": "Cynthia C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "Cynthia Waters",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Stephen S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Leslie Beck",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Krystal W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Edward Watson DDS",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brenda H.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.8,
        "customer_name": "Stacey Hanson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tyler H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Anthony Mccoy",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nicholas M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Steve Thornton",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Susan H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Brianna Brown",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jeffery R.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Eric Hall",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Shawn M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-13",
        "review_rate": 5.0,
        "customer_name": "Luis Gonzalez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Vickie B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Ashley Burke",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Carl B.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Joshua Turner",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Christine C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "Jennifer Garcia",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mariah F.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Anthony Friedman",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Joel R.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Lori Castillo",
        "service_type": "Garage Door Services",
        "tech_name": "Kristen W.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Christine Massey",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Gregory M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Natalie Davis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Rick M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Dwayne Good",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Valerie M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.8,
        "customer_name": "Manuel Williams",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Richard O.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Eric Harrison",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Cassandra S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Sally Bruce",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Charles V.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Katelyn Reynolds",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Victoria F.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Kim Reed",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Eric S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Rodney Mcknight",
        "service_type": "Garage Door Off Track",
        "tech_name": "Denise G.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Rodney Ochoa",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Austin H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Jennifer Savage",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tim P.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Jessica Johnson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Caleb V.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-30",
        "review_rate": 5.0,
        "customer_name": "Robin Goodwin",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Steven B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "Laura Schultz",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Margaret B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Christopher Phillips",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Steven B.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Jennifer Garcia",
        "service_type": "Garage Door Opener",
        "tech_name": "Jeremy P.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.8,
        "customer_name": "Steven Mcdowell",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Trevor G.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Sarah Bell",
        "service_type": "Garage Door Opener",
        "tech_name": "Christian H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Kimberly Sandoval",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Glenn G.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.8,
        "customer_name": "Cynthia Weiss",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ryan W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Sierra Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Maria L.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Glenn Klein",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brendan W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Deborah Bennett",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kim D.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Allison Dixon",
        "service_type": "Garage Door Services",
        "tech_name": "Ashley C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Brandon Harrington IV",
        "service_type": "Garage Door Repair",
        "tech_name": "Benjamin S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Christopher Ramirez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Rachel C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "Tracy Dixon",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.8,
        "customer_name": "Matthew Ramos",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Laura B.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Sarah Pollard",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kevin F.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Madison Li",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jeffrey M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Shelly Ramirez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Courtney A.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Robert Ramsey",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "James B.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Kristina Lane",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Chad S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-03",
        "review_rate": 5.0,
        "customer_name": "Christopher Burns",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Elizabeth D.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Victoria Hubbard",
        "service_type": "Garage Door Insulation",
        "tech_name": "James T.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Jesus Brown",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Emily F.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-04",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Bennett",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "John G.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Griffith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Cassandra J.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Andrea Russell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Emily B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Scott Sharp",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Bobby M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Courtney Crawford",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Katrina V.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.8,
        "customer_name": "Edwin Sampson",
        "service_type": "Garage Door Services",
        "tech_name": "Christine S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Sarah Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lee A.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Ashley Ortiz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher P.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Charles Johnson",
        "service_type": "Garage Door Opener",
        "tech_name": "Jeffery P.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Jones",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Cheryl J.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Joseph Mendoza",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "John R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.8,
        "customer_name": "Brandon Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christian M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Anna Patel",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Alan J.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-07",
        "review_rate": 5.0,
        "customer_name": "Kurt Fox",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Cheyenne F.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Mrs. Destiny Wood",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sandra F.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Michael Odom",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael A.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Teresa Jackson MD",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Todd W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-16",
        "review_rate": 5.0,
        "customer_name": "Stephen Rose",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Elizabeth H.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Joshua Lindsey",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Katherine O.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Bradley Hoffman",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Dustin K.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Daniel Odom",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Paul L.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Angela Wagner",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Heather G.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Patricia Gill",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jennifer S.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "Diane Luna",
        "service_type": "Garage Door Installation",
        "tech_name": "Christina M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Amy Robinson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brenda H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Michelle Simpson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Elizabeth N.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Joyce Mcpherson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jaime S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Mathew Hebert",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Katie J.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Leslie Wu",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Robin M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Isaiah Johnson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jennifer P.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "Ronald Hill",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jesse E.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.8,
        "customer_name": "Shannon Solomon",
        "service_type": "Garage Door Services",
        "tech_name": "Henry W.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.8,
        "customer_name": "Gabrielle Jones",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Johnathan H.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Amanda Kelley",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Allison M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "John Wright",
        "service_type": "Garage Door Services",
        "tech_name": "Emily P.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Ashley Alvarez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jerome M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Karen Ramsey",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kari S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Michele Mcdonald",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Richard R.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Marcus Mckee",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brett D.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Rebecca Wagner",
        "service_type": "Garage Door Off Track",
        "tech_name": "Annette C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Anthony Chang",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Edward P.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Gary Pearson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Manuel A.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Shawn Martin",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Ryan G.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Holly Luna",
        "service_type": "Garage Door Installation",
        "tech_name": "Morgan M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Travis Bullock",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michelle N.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Daniel Carpenter",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jennifer C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-23",
        "review_rate": 5.0,
        "customer_name": "Mrs. Dana Hays",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ruben D.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Jennifer Thompson",
        "service_type": "Garage Door Repair",
        "tech_name": "Audrey S.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Brenda Lucas",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mackenzie W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Alexandria Miller",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "James M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Mike Martinez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Tracey J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "David Robinson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Lisa D.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Isaac Hall",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Micheal P.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Walter Long",
        "service_type": "Garage Door Opener",
        "tech_name": "Elizabeth L.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Martin Mccormick",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Anthony F.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Kimberly Callahan",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Hailey J.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "David Rivers",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Katie W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Cheryl Dalton",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Benjamin M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Amber Reeves",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Matthew C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Rachel Chavez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Stephanie B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Patrick Wall",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tanner A.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Kimberly Farley",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kathryn C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Christina Nelson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jeremy C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Jennifer Vega",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "James T.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Jaclyn Vasquez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Daniel M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-25",
        "review_rate": 5.0,
        "customer_name": "John Kline",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Louis C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Tony Gordon",
        "service_type": "Garage Door Repair",
        "tech_name": "Laurie G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Jeremy Gonzalez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kristy W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Thomas Hoffman",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Scott H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Jessica Bush",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kenneth T.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Angel Greer",
        "service_type": "Garage Door Repair",
        "tech_name": "Aaron L.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Tiffany Perkins",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael L.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-03",
        "review_rate": 5.0,
        "customer_name": "Miguel Estes",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "David P.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "James Deleon",
        "service_type": "Garage Door Insulation",
        "tech_name": "Donald H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Christopher Russell MD",
        "service_type": "Garage Door Repair",
        "tech_name": "Vanessa M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-07",
        "review_rate": 5.0,
        "customer_name": "Eric Tucker",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Dalton S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Anna Faulkner",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Laura E.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Gary Ramsey",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jason D.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Laura Coleman",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Shari L.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Michael Bird",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Donald P.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Amanda Dean",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Douglas S.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Brewer",
        "service_type": "Garage Door Services",
        "tech_name": "Andrew B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-22",
        "review_rate": 5.0,
        "customer_name": "Dana Davis",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Shannon M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Courtney Hayes",
        "service_type": "Garage Door Services",
        "tech_name": "Leah N.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Carolyn Thomas",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Johnathan M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Michael Moore",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "James R.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Kevin Byrd",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Justin Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jennifer C.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Angela Perkins",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sierra G.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "James Hanson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brian A.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Shirley Ortega",
        "service_type": "Garage Door Repair",
        "tech_name": "Sarah L.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Paul James",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "William S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Brad Murray",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Alexander B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Joshua Mills",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Charlene C.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Peter Rivas",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michael H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Kristin Thomas",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Laurie R.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Allison Wallace",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tanner N.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Derek Hernandez",
        "service_type": "Garage Door Installation",
        "tech_name": "Max H.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Shelby Massey",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jason S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.8,
        "customer_name": "Andrew Wood",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michael F.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Melinda Jones",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Justin C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "April Jackson",
        "service_type": "Garage Door Repair",
        "tech_name": "Kevin P.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.8,
        "customer_name": "Jason Johnson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Amy T.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Gary Cole",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Shannon A.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.8,
        "customer_name": "Matthew Rogers",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Matthew A.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Michael Williams",
        "service_type": "Garage Door Opener",
        "tech_name": "Danielle H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Jeffrey West",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ronald C.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Bryce Duffy",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jesse H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Erin Ochoa",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Crystal D.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Lee Estes",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ryan G.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Lance Hensley",
        "service_type": "Garage Door Repair",
        "tech_name": "Donna H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Mary Griffin",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Rebecca R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Danielle Hayes",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lindsay M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Ryan Cline",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mark V.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Briana Obrien",
        "service_type": "Garage Door Installation",
        "tech_name": "Emily W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Ann Carter",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ryan C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Maureen Hodges",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Andrew H.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Margaret Gonzalez MD",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kathryn H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Daniel Gibson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brett V.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Tracy Weaver DDS",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Deborah L.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.8,
        "customer_name": "Christopher Johnson",
        "service_type": "Garage Door Services",
        "tech_name": "Gordon T.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Aaron Hardy",
        "service_type": "Garage Door Off Track",
        "tech_name": "Bryan S.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Joshua Jackson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Rebecca M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Kaylee Richards",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Rachel C.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Christina Fields",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Andrea G.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Nathaniel Stevens",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Abigail J.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Valerie Cross",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Peter R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "William Flores",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Melissa C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Debbie Smith",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jonathan B.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "Nicole Mccoy",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Peter S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Justin Gutierrez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Robert S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "Jeffrey French",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jeffery B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Mr. Bradley Rivera DDS",
        "service_type": "Garage Door Repair",
        "tech_name": "Scott K.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Joseph Conner",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Juan P.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-04",
        "review_rate": 5.0,
        "customer_name": "Jonathan Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Zachary S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Phillip Ross",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ryan L.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Nicole Lucero",
        "service_type": "Garage Door Repair",
        "tech_name": "Laura J.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Douglas Meyer",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Olivia T.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Jennifer Smith",
        "service_type": "Garage Door Services",
        "tech_name": "Cynthia B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Matthew Chambers",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christopher R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Gary Webb",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "James K.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Mario Hernandez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tamara W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Cristian Mcdonald",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Karen K.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Michael Martinez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Angela H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "George Shannon",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "David M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Cynthia Young",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Nicole F.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Nicholas Ponce",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jeffrey S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-05",
        "review_rate": 5.0,
        "customer_name": "Jose Marsh",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Grant Y.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Katherine Alexander",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Alison C.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Timothy Ruiz",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Tina P.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Jessica Sanchez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Bruce C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
